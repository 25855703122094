<template>
<div style="display: flex; flex-direction: row;">

    <div class="o365-footer-pinned-left" v-if="dataGridControl.dataColumns.hasPinnedLeftColumns"
    :style="[{'min-width':dataGridControl.dataColumns.leftPinnedWidth + 'px'},{'max-width':dataGridControl.dataColumns.leftPinnedWidth + 'px'},{'width':dataGridControl.dataColumns.leftPinnedWidth + 'px'}]">

        <div class="o365-footer-row">
            <span v-if="showTotalLabel" class="px-2" style="padding: 0.32em">{{$t('Total')}}</span>
            <template v-for="(col, colIndex) in dataGridControl.dataColumns.columns" :key="colIndex">
                <template v-if="!col.hide && col.pinned === 'left'">
                    <div class="o365-body-cell" tabindex="-1" :data-o365-colindex="colIndex"
                        :title="$t(col.summaryAggregate)"
                        :class="[{'o365-summary-cell': col.summaryAggregate}, col.cellClass]"
                        :style="[{'width': col.width + 'px'},{'left': col.left + 'px'}, {'user-select': col.editable ? 'none':''}, ...col.cellStyles]">
                        <template v-if="col.summaryAggregate != null || col.summarySlot != null">
                                <component v-if="col.summarySlot" :is="col.summarySlot" :row="dataGridControl.summaryData.summary" :column="col" :isLoading="dataGridControl.summaryData.isLoading"/>
                                <template v-else>
                                    {{ formatValue(dataGridControl.summaryData.summary[col.field], col) }}
                                </template>
                        </template>
                    </div>
                </template>
            </template>
        </div>

    </div>

    <div class="o365-footer-viewport">
        <div class="o365-grid-container " :style="[{'width':dataGridControl.dataColumns.centerWidth + 'px'}]">

            <div class="o365-footer-row" >
                <template v-for="(col, colIndex) in dataGridControl.dataColumns.columns" :key="colIndex">
                    <template v-if="!col.hide && !col.pinned">
                        <div class="o365-body-cell" tabindex="-1" :dataO365Colindex="colIndex"
                            :title="$t(col.summaryAggregate)"
                            :class="[{'o365-summary-cell': col.summaryAggregate}, col.cellClass]"
                            :style="[{'width': col.width + 'px'},{'left': col.left + 'px'}, {'user-select': col.editable ? 'none':''}, ...col.cellStyles]">
                            <template v-if="col.summaryAggregate != null || col.summarySlot != null">
                                <component v-if="col.summarySlot" :is="col.summarySlot" :row="dataGridControl.summaryData.summary" :column="col" :isLoading="dataGridControl.summaryData.isLoading"/>
                                <template v-else>
                                    {{ formatValue(dataGridControl.summaryData.summary[col.field], col) }}
                                </template>
                            </template>
                        </div>
                    </template>
                </template>
            </div>

        </div>
    </div>

    <div class="o365-footer-pinned-right" v-if="dataGridControl.dataColumns.hasPinnedRightColumns"
    :style="[{'min-width':dataGridControl.dataColumns.rightPinnedWidth + 17 + 'px'},{'max-width':dataGridControl.dataColumns.rightPinnedWidth + 17 + 'px'},{'width':dataGridControl.dataColumns.rightPinnedWidth + 17 + 'px'}]">

        <div class="o365-footer-row">

            <template v-for="(col, colIndex) in dataGridControl.dataColumns.columns" :key="colIndex">
                <template v-if="!col.hide && col.pinned === 'right'">
                    <div class="o365-body-cell" tabindex="-1" :dataO365Colindex="colIndex"
                        :title="$t(col.summaryAggregate)"
                        :class="[{'o365-summary-cell': col.summaryAggregate}, col.cellClass]"
                        :style="[{'width': col.width + 'px'},{'left': col.left + 'px'}, {'user-select': col.editable ? 'none':''}, ...col.cellStyles]">
                            <template v-if="col.summaryAggregate != null || col.summarySlot != null">
                                <component v-if="col.summarySlot" :is="col.summarySlot" :row="dataGridControl.summaryData.summary" :column="col" :isLoading="dataGridControl.summaryData.isLoading"/>
                                <template v-else>
                                    {{ formatValue(dataGridControl.summaryData.summary[col.field], col) }}
                                </template>
                            </template>
                    </div>
                </template>
            </template>
        </div>

    </div>

</div>
</template>

<script setup lang="ts">
import type DataGridControl from 'o365.controls.DataGrid.ts';
import type DataColumn from 'o365.controls.DataGrid.Column.ts;'

import 'o365.modules.DataObject.extensions.SummaryData.ts';
import 'o365.controls.DataGrid.extensions.SummaryData.ts';
import utils from 'o365.modules.utils.js';
import { onMounted, computed, onBeforeUnmount } from 'vue';
const props = defineProps<{
    dataGridControl: DataGridControl
}>();

let addedColumnDebounce: number|null = null;
async function handleAddedColumn() {
    if (addedColumnDebounce != null) { window.clearTimeout(addedColumnDebounce); }
    addedColumnDebounce = window.setTimeout(() => {
        updateAggregates();
        addedColumnDebounce = null;
    }, 100);
}

function updateAggregates() {
    const aggregateColumns = props.dataGridControl.dataColumns.columns.filter(col => col.summaryAggregate).map(col => ({ name: col.field, aggregate: col.summaryAggregate, round: col.summaryRound}));
    props.dataGridControl.summaryData.setAggregates(aggregateColumns);
    props.dataGridControl.summaryData.fetchAggregates();
    if (props.dataGridControl.props.alwaysReloadSummaryRow != null) {
        props.dataGridControl.summaryData.skipLoadChecks = props.dataGridControl.props.alwaysReloadSummaryRow;
    }
}
props.dataGridControl.updateSummaryAggregates = updateAggregates;

const showTotalLabel = computed(() => !props.dataGridControl.dataColumns.leftColumns[0]?.hide && !props.dataGridControl.dataColumns.leftColumns[1]?.hide);

function formatValue(pValue: any, pColumn: DataColumn) {
    if (pColumn.summaryFormat) {
        if (['date', 'datetime', 'time'].indexOf(pColumn?.type) > -1) {
            return utils.formatDate(pValue, pColumn.pColumn.summaryFormat, pColumn.utc);
        } else if (pColumn?.type === 'number') {
            return utils.formatNumber(pValue, pColumn.summaryFormat);
        } else {
            return pValue;
        }
    } else {
        return utils.format(pValue, pColumn);
    }
}

onMounted(() => {
    props.dataGridControl.on('ColumnAdded', handleAddedColumn)
    updateAggregates();
});

onBeforeUnmount(() => {
    props.dataGridControl.updateSummaryAggregates = undefined;
    props.dataGridControl?.off('ColumnAdded', handleAddedColumn)
});
</script>
